import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {ExpressFast} from "@/components/modules/ExpressFastOrdersModule/models/ExpressFast";


export function ExpressFastOrders() {

    this.checkValid = checkValid

    this.data = {
        id: '',
        comment: '',
        status: null,
        createdAt: null,
        relatedOrders: [],
        ratesListSelected: null,
    }

    this.validation = {
        id: false,
        comment: false,
        status: false,
        ratesListSelected: false,
    }

    this.validationTranslate = {
        id: '',
        comment: '',
        status: '',
        ratesListSelected: '',
    }

    this.validationTxt = {
        id: false,
        comment: false,
        status: false,
        ratesListSelected: false,
    }

    /**
     * Getters
     */
    this.getId = () => {
        return this.data.id
    }
    this.getComment = () => {
        return this.data.comment
    }
    this.getStatus = () => {
        return this.data.status
    }
    this.getCreatedAt = () => {
        return this.data.createdAt
    }
    this.getSendAt = () => {
        return this.data.sendAt
    }
    this.getRelatedOrders = () => {
        return this.data.relatedOrders
    }
    this.getRatesListSelected = () => {
        return this.data.ratesListSelected
    }

    /**
     * Setters
     */
    this.setId = (val) => {
        this.data.id = val
    }
    this.setComment = (val) => {
        this.data.comment = val
    }
    this.setStatus = (val) => {
        this.data.status = val
    }
    this.setCreatedAt = (val) => {
        this.data.createdAt = val
    }
    this.setRelatedOrders = (val) => {
        this.data.relatedOrders = val
    }
    this.setRatesListSelected = (val) => {
        this.data.ratesListSelected = val
    }
}

/**
 * Global Setters
 */
ExpressFastOrders.prototype.setExpressFastOrdersItem = function (items) {
    if(items.length) {
        this.setId(items[0]?.order_express_fast_order_id)
    }

    let sortItems = items.sort((a, b) => parseFloat(b.sort_index) - parseFloat(a.sort_index))

    if (sortItems && sortItems.length) {
        sortItems.forEach(order => {
            this.addRelatedOrders(order)
        })
    }
}

/**
 * Functions
 */

ExpressFastOrders.prototype.addRelatedOrders = function (val) {
    let order = new ExpressFast()

    order.setId(val.order_express_id)
    order.setOrderObj(val)
    order.setChangedDimensions(val?.is_size_and_weight_set)
    order.data.OldDimensions.setDimensionsItem({
        weight: val?.old_weight,
        height: val?.old_height,
        width: val?.old_width,
        dimensionLength: val?.old_length,
    })
    order.data.Dimensions.setDimensionsItem({
        weight: val?.new_weight,
        height: val?.new_height,
        width: val?.new_width,
        dimensionLength: val?.new_length,
    })
    this.data.relatedOrders.unshift(order)
}

ExpressFastOrders.prototype.removeRelatedOrders = function (val) {
    this.data.relatedOrders.splice(val, 1)
}

/**
 * Prepare Data
 */

ExpressFastOrders.prototype.prepareSave = function () {


    let preparedData = {
        'comment': '',
    }

    if (this.getRelatedOrders().length > 0) {
        let index = 0
        preparedData['elements'] = this.data.relatedOrders.map(item => {
            let data = {
                'id': item.data.orderObj?.id,
                'sort_index': index,
                'order_express_id': item.data.id,
                'proform_packaging': {
                    'weight': item.data.Dimensions.data.weight,
                    'length': item.data.Dimensions.data.dimensionLength,
                    'width': item.data.Dimensions.data.width,
                    'height':  item.data.Dimensions.data.height,
                },
                'update-size-weight': item.data.changedDimensions || item.data.isNewDimensions ||
                  (item.data.Dimensions.data.weight && !item.data.changedDimensions && !item.data.isNewDimensions),
            }
            index++
            return data
        })
    }

    console.log(preparedData);

    return preparedData
}

/**
 * Validations
 */

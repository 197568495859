import {socketMixin} from "../socketMixins/socketMixin";

export const fastOrdersMixin = {

	methods: {

		checkPermission(){
			// return this.checkOrderTypePermission({
			// 	types: {
			//
			// 	},
			// 	currentType: this.$route.params.type
			// })
			return true
		},

		saveFastOrders(edit = false, createLabels = false) {

			// if (!this.FO.firstValidationAdmin()) return

			let data = this.FO.prepareSave()
			let saveType = 'createFastOrders'

			if (edit) {
				data = {
					id: this.FO.getId(),
					data: data
				}
				saveType = 'updateFastOrders'
			}

			console.log(data);
			console.log(saveType);

			return this.$store.dispatch(saveType, data).then(response => {
				if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')
				console.log(data);

				switch (this.getResponseStatus(response)) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

						if (edit) {
							this.openNotify('success', 'common_notificationRecordChanged')
						} else {
							this.openNotify('success', 'common_notificationRecordCreated')
						}

						if (createLabels) {
							let id = this.getRespData(response)?.order_id
							this.createLabelsForFastOrders(id, false)
						}
						else {
							this.$router.push(this.$store.getters.GET_PATHS.reportsFastOrders)
						}
						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})

		},

		createLabelsForFastOrders(id = false, noticeFlag = true) {
			if (!id) {
				id = this.FO.getId()
			}

			return this.$store.dispatch('createFastOrdersLabel', id).then(response => {
				if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')
				console.log(response);

				switch (this.getResponseStatus(response)) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

						if (noticeFlag) {
							this.openNotify('success', 'common_notificationRecordChanged')
						}

						this.$store.commit('changeFastOrdersInitSocket')
						this.$store.commit('setFastOrdersSocketItemId', id)
						console.log('check init socket', this.$store.getters.getFastOrdersInitSocket);
						this.$router.push(this.$store.getters.GET_PATHS.reportsFastOrders)
						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})
		},
	}
}

export const fastOrdersSocketMixin = {
	mixins: [socketMixin],

	methods: {
		createFastOrdersSocket(prepareSocket, functionEvent){
			this.$store.commit('setFastOrdersSocket', true)

			window.Echo.private(`${prepareSocket.channel}.${prepareSocket.user}`)
				.listen(`.${prepareSocket.event}`, (response) => {
					functionEvent(response)
				}).error(e => {
				console.log(e);
			})
		}
	}
}
import {ExpressFastOrders} from "@/components/modules/ExpressFastOrdersModule/models/ExpressFastOrders";
import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";

export const expressFastOrdersMixin = {

	mixins: [routeFilter],

	methods: {

		exportExpressFastOrders(id) {

			this.$store.dispatch('getExportExpressFastOrders', id ? id : this.EFO.data.id).then(response => {
				switch (this.getResponseStatus(response)) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
						this.createDownloadLink({data: response.data, name: `Express report.xlsx`, type: 'xlsx'})
						this.openNotify('success', 'common_notificationRecordCreated')
						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})
		},

		reload() {
			this.loaded = false
			this.EFO = new ExpressFastOrders()
			this.initExpressFastOrdersEdit()
		},

		createLabel(createLabel, edit, order) {

			if(!order?.order_express_fast_order) return this.openNotify('error', 'common_notificationUndefinedError')

			let foundElem = order.order_express_fast_order.elements.find(elem => {return elem?.order_express_id === createLabel})

			if(!foundElem) return this.openNotify('error', 'common_notificationUndefinedError')

			return this.$store.dispatch('reCreateLabelExpressFastOrders', {idReport: order?.order_express_fast_order?.id, idElement: foundElem?.id}).then(response => {
				switch (this.getResponseStatus(response)) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
						this.openNotify('success', 'common_notificationRecordCreated')
						this.reload()
						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})


		},

		async takePhoto(order) {
			// Знаходимо елемент <video> в HTML-документі
			const video = document.querySelector('video');
			let mediaStream = null;

			// Запускаємо стрім з веб-камери
			try {
				mediaStream = await navigator.mediaDevices.getUserMedia({ video: {
						width: { min: 640, ideal: 1920, max: 1920 },
						height: { min: 480, ideal: 1080, max: 1080 }
					}});
				console.log(222);
				video.srcObject = mediaStream;
				await video.play();
			} catch (err) {
				console.error("Не можу отримати доступ до відеокамери: ", err);
				return;
			}

			await new Promise(resolve => setTimeout(resolve, 10));

				// Знаходимо елемент <button> в HTML-документі
				// const button = document.querySelector('button');

				// Оброблюємо клік на кнопці
				// button.addEventListener('click', () => {
				// Створюємо елемент <canvas>
				const canvas = document.createElement('canvas')
				canvas.width = video.videoWidth
				canvas.height = video.videoHeight

				// Отримуємо контекст рисування для <canvas>
				const context = canvas.getContext('2d')
				context.drawImage(video, 0, 0, canvas.width, canvas.height)

				// Конвертуємо зображення з <canvas> в data URL
				const dataURL = canvas.toDataURL('image/png')

				// Виводимо зображення на сторінку

				if(dataURL.length < 10) return

				let files = []
				this.EFO.data.relatedOrders.forEach(o => {
					if(o.data.takePhoto) {
						// alert(o.data.id)
						files.push({
							"order_express_id": o.data.id,
							"file1_base64": dataURL
						})
					}
				})

				let data = {files}

				await this.$store.dispatch('sendExpressPhotoFastOrder', {id: order?.order_express_fast_order?.id, data}).then(response => {
					switch (this.getResponseStatus(response)) {
						/**
						 * Success
						 */
						case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
							// this.setProductPhoto(this.getRespData(response).photos)

							if (mediaStream) {
								// Отримати всі доріжки медіа в потоці
								const tracks = mediaStream.getTracks()

								// Зупинити всі доріжки медіа в потоці
								tracks.forEach(track => track.stop())

								// Звільнити ресурси
								mediaStream = null
							}
							break
						}
						/**
						 * Validation Error
						 */
						case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
							let errors = response.response.data.errors;
							this.notifyErrorHelper(errors)
							break
						}
						/**
						 * Undefined Error
						 */
						default: {
							this.openNotify('error', 'common_notificationUndefinedError')
						}
					}
				})
		},

		saveExpressFastOrders(edit = false, createLabel = false, reload = false, takePhoto = false) {

			// if (!this.FO.firstValidationAdmin()) return

			console.log(createLabel);

			let data = this.EFO.prepareSave()

			let saveType = 'createExpressFastOrders'

			if (edit) {
				data = {
					id: this.EFO.getId(),
					data: data
				}
				saveType = 'updateExpressFastOrders'
			}

			this.$store.dispatch(saveType, data).then(async response => {
				console.log(data);

				switch (this.getResponseStatus(response)) {
					/**
					 * Success
					 */
					case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

						if(this.getRespData(response)?.label_files && this.getRespData(response).label_files.length) {
							window.open(this.$store.getters.GET_PATHS.getPdfFile + '/' + this.getRespData(response).label_files[0]?.uuid + '?print=true', '_blank');
						}

						if(takePhoto) {
							await this.takePhoto(this.getRespData(response))
						}

						if(this.getRespData(response)?.warnings) {
							this.openNotify('warn', {
								txtServer: this.getRespData(response)?.warnings[0]
							})
						}

						if(createLabel) {
							this.createLabel(createLabel, edit, this.getRespData(response))
							return
						}

						if(reload && !edit) {
							this.openNotify('success', 'common_notificationRecordCreated')
							this.$router.push(this.$store.getters.GET_PATHS.reportsExpressFastOrdersEdit + '/' + this.getRespData(response)?.order_express_fast_order?.id)
							return
						}

						if(reload && edit) {
							this.openNotify('success', 'common_notificationRecordChanged')
							this.reload()
							return
						}

						// if (createLabels) {
						// 	let id = this.getRespData(response)?.order_id
						// 	this.createLabelsForFastOrders(id, false)
						// }
						// else {
						// }

						if (edit) {
							this.openNotify('success', 'common_notificationRecordChanged')
						} else {
							this.openNotify('success', 'common_notificationRecordCreated')
						}

						this.$router.push(this.$store.getters.GET_PATHS.reportsExpressFastOrders)
						break
					}
					/**
					 * Validation Error
					 */
					case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
						let errors = response.response.data.errors;
						this.notifyErrorHelper(errors)
						break
					}
					/**
					 * Undefined Error
					 */
					default: {
						this.openNotify('error', 'common_notificationUndefinedError')
					}
				}
			})

		},


	}
}